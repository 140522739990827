import React from 'react'
import { Helmet } from 'react-helmet'

const PageTitle = ({ title }) => {
  return (
    <Helmet>
      <title>WiaSoft - {title}</title>
      <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Yazılım Şirketi",
              "url": "https://wiasoft.com",
              "name": "Wia Yazılım",
              "contactPoint": {
                "@type": "İletişim",
                "telephone": "+90-850-308-0942",
                "contactType": "Destek Hattı"
              }
            }
          `}
      </script>
    </Helmet>
  )
}

export default PageTitle