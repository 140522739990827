import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import PageTitle from "../components/PageTitle";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const DocumentList = React.lazy(() => import("../components/DocumentList"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const Footer = React.lazy(() => import("../components/Footer"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const Document = () => {
    return (
        <>
            <PageTitle title={"Döküman"} />
            <Fragment>
                <Suspense fallback={<Preloader />}>
                    <SearchPopup />
                    <Navbar />
                    <Breadcrumb title={"Dökümanlar"} />
                    <DocumentList />
                    <Footer />
                    <FooterBottomOne />
                </Suspense>
            </Fragment> 
        </>
    )
}

export default Document