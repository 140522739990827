import React, { Fragment, Suspense } from 'react'
import Preloader from "../../elements/Preloader";
import PageTitle from "../../components/PageTitle";
const Breadcrumb = React.lazy(() => import("../../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() => import("../../components/FooterBottomOne"));
const Footer = React.lazy(() => import("../../components/Footer"));
const Navbar = React.lazy(() => import("../../components/Navbar"));
const SearchPopup = React.lazy(() => import("../../elements/SearchPopup"));
const MobilePrivacy = () => {

    const pdfUrl = "/assets/docs/mobil-uygulama-gizlilik-bildirimi.pdf";
    return (
        <>
            <PageTitle title={"Mobil Uygulama Gizlilik Politikası"} />
            <Fragment>
                <Suspense fallback={<Preloader />}>
                    <SearchPopup />
                    <Navbar />
                    <Breadcrumb title={"Mobil Uygulama Gizlilik Politikası"} />
                    <div className='container'>
                        <div style={{ width: '100%', height: '100vh' }}>
                            <iframe
                                title="PDF Viewer"
                                src={pdfUrl}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                allowFullScreen
                            />
                        </div>
                    </div>
                    <Footer />
                    <FooterBottomOne />
                </Suspense>
            </Fragment>
        </>
    )
}

export default MobilePrivacy