import React from "react";
import { desktopSoftwareServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const DesktopSoftwareServiceDetailPage = () => {
  const data = desktopSoftwareServiceData;
  return (
    <>
      <PageTitle title={"Profesyonel Masaüstü Yazılım Hizmetleri"} />
      <ServiceDetailsInner data={data} />
    </>
  );
};

export default DesktopSoftwareServiceDetailPage;
