import React from "react";
import { mobileSoftwareServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const MobileSoftwareServiceDetailPage = () => {
  const mobileData = mobileSoftwareServiceData;
  return (
    <>
      <PageTitle title={"Profesyonel Mobil Yazılım Hizmetleri"} />
      <ServiceDetailsInner data={mobileData} />
    </>
  );
};

export default MobileSoftwareServiceDetailPage;
