import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import PageTitle from "../components/PageTitle";
const AboutTwo = React.lazy(() => import("../components/AboutTwo"));
const Banner = React.lazy(() => import("../components/Banner"));
/* const BlogTwo = React.lazy(() => import("../components/BlogTwo")); */
const Counter = React.lazy(() => import("../components/Counter"));
const FaqOne = React.lazy(() => import("../components/FaqOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const Footer = React.lazy(() => import("../components/Footer"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const Partner = React.lazy(() => import("../components/Partner"));
const Service = React.lazy(() => import("../components/Service"));
const Testimonial = React.lazy(() => import("../components/Testimonial"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsTwo"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const HomeTwo = () => {
  return (
    <>
      <PageTitle title={"Wia Yazılım"} />
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar */}
          <Navbar />

          {/* Banner Two */}
          <Banner />

          {/* Partner Two */}
          <Partner />

          {/* About Two */}
          <AboutTwo />

          {/* Counter Two */}
          <Counter />

          {/* Service Two */}
          <Service />

          {/* Why Choose Us Two */}
          <WhyChooseUsTwo />

          {/* Testimonial Two */}
          <Testimonial />

          {/* Video Area One */}
          <VideoAreaOne />

          {/* Faq One */}
          <div className='faq-area pd-top-120'>
            <FaqOne />
          </div>

          {/* Blog Two */}
          {/* <BlogTwo /> */}

          {/* Footer Two */}
          <Footer />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
