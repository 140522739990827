import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import PageTitle from "../components/PageTitle";
const About = React.lazy(() => import("../components/About"));
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const Counter = React.lazy(() => import("../components/Counter"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const FooterOne = React.lazy(() => import("../components/Footer"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const Partner = React.lazy(() => import("../components/Partner"));
const SkillOne = React.lazy(() => import("../components/SkillOne"));
const Testimonial = React.lazy(() => import("../components/Testimonial"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const AboutPage = () => {
  return (
    <>
      <PageTitle title={"Hakkımızda"} />

      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <Navbar />

          {/* Breadcrumb */}
          <Breadcrumb title={"Hakkımızda"} />

          {/* About One */}
          <div className='pd-top-120 pd-bottom-120'>
            <About />
          </div>

          {/* Counter One */}

          <div className='fact-area' style={{ background: "#f9f9f9" }}>
            <Counter />
          </div>

          {/* Skill One */}
          <SkillOne />

          {/* Video Area One */}
          <VideoAreaOne />

          {/* Team One */}
          {/* <div className='pd-bottom-80'>
            <TeamOne />
          </div> */}

          {/* Testimonial Two */}
          <Testimonial />

          {/* Partner One */}
          <Partner />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default AboutPage;
